$('[name="transferProduct[]"], [name^="transferQuantity"]').change(function(){
	if($(this).attr('name').search('transferProduct') + 1){
		if($(this).is(':checked')){
			count = $(this).closest('tr').find('[name^="transferQuantity"] option:selected').val() == undefined ? 1 : $(this).closest('tr').find('[name^="transferQuantity"] option:selected').val();
			$(this).closest('tr').find('[name^="transferQuantity"]').data('lastcount',count);
			$('#cash_return').text($('#cash_return').text()-0 + ($(this).closest('tr').data('price') * count - 0));
		} else {
			count = $(this).closest('tr').find('[name^="transferQuantity"] option:selected').val() == undefined ? 1 : $(this).closest('tr').find('[name^="transferQuantity"] option:selected').val();
			$(this).closest('tr').find('[name^="transferQuantity"]').data('lastcount',count);
			$('#cash_return').text($('#cash_return').text()-0 - ($(this).closest('tr').data('price')* count - 0));
		}
	}
	if($(this).attr('name').search('transferQuantity') + 1 && $(this).closest('tr').find('[name="transferProduct[]"]').is(':checked') ){
		count = $(this).val() - $(this).data('lastcount');
		$('#cash_return').text($('#cash_return').text()-0 + ($(this).closest('tr').data('price') * count));
		$(this).data('lastcount',$(this).val());
	}
});
$('.go_input_data').click(function(){
	if($('[name="transferProduct[]"]:checked').length == 0){
		$('.error_dont_select_prod').show();
		return false;
	}
	$('.error_dont_select_prod').hide();
	$('.input_data').show();
	$(this).closest('.choose_product').hide();
	$('[name="summa"]').val($('#cash_return').text());
});
$('.show_choose_product').click(function(){
	$('[name="transferProduct[]"]:checked').trigger('click');
	$('#cash_return').text('0.00');
	$('.choose_product').show();
	$(this).closest('.input_data').hide();
});