$(document).ready(function(){
	var data = {};
	data.products_id = $('#add_product-form > input[name=products_id]').val();
	if(!data.products_id){
		return true;
	}
	var itemHolder = $('.product-section');
	data.action = 'recalc_price';
	$.ajax({
		type: "GET",
		url: "/ajax_fetch_coupons.php",
		data: data,
		dataType : "json",
		success: function( msg ){
			var CurPrice = parseInt( $('.current_price', itemHolder).html().replace(/\D+/g,"") );
					
			if( msg !== null ) {

				if( CurPrice != msg['price'] && msg['price'] != 0 ){
					// ������ ����
					$('.current_price', itemHolder).html(msg['price'] + ' ' + PROSKTR.currencies.right);
					// var pct = Math.round( (msg['start_price'] - msg['price'])*20/msg['start_price'] ) * 5;
					var pct = Math.round( (msg['start_price'] - msg['price'])/msg['start_price'] * 100 );
					
					if( $('.old-price', itemHolder).length > 0 ){
						$('.sale-badge', itemHolder).html( '-' + pct + '%' );
						$('.coupon-price-label', itemHolder).html(' (� ������ �����)');
					}else{
						$('.old-price-holder', itemHolder).append('<span class="sale-badge">-' + pct + '%</span><span class="old-price">' + msg['start_price'] + ' ' + PROSKTR.currencies.right + '</span>' + ( msg['coupon_work'] != '' ? '<span class="coupon-price-label"> (� ������ �����)</span>' : '' ));
					}
				}
			}
		}
	});
});