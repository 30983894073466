$(function(){
	initProducts();

	/**
	 * ��� �������� ���� ��� ��������� ��������
	 * @returns {boolean}
	 */
	function initProducts(){
		var isIndexProductsPage = $('.content-index_products').length,
			isSearchPage = $('.content-advanced_search_result').length;

		if(!(isIndexProductsPage || isSearchPage)){
			return true;
		}
	}
});