

/**
 * ������ ������������ ���� ��������� � ����� �����, ������� ����� ��������
 * @type array
 */
var Fields2show = {};

Fields2show.mail_theme = ['mail_sub_theme-container'];

/*��� ������*/
Fields2show['��� ��� �����?'] = ['contact_us-order_id'];
Fields2show['������ ������'] = ['contact_us-order_id'];
Fields2show['��������� ������'] = ['contact_us-order_id'];
Fields2show['������ ������'] = ['contact_us-order_id'];
Fields2show['�������'] = ['contact_us-order_id', 'contact_us-application'];

Fields2show['����� ��������'] = ['contact_us-city'];
Fields2show['������������ �����-���'] = ['contact_us-promo_code'];

Fields2show['� ���� ������ �� ������'] = ['contact_us-product_link'];
Fields2show['���������� � ��������'] = ['contact_us-product_link'];

Fields2show['��������'] = ['contact_us-file'];
Fields2show['�����������'] = ['contact_us-file'];

Fields2show['������'] = ['contact_us-file'];

/**
 * ��������� ���� �����, ������� ����� �������� ��� ������ ���� ��� �������
 * @param string fieldname
 * @returns {undefined}
 */
function contact_us_show_fields(fieldname) {
    fields_array = window['Fields2show'][fieldname];

    $(".contact_us-must_hide").hide().removeData('required');

    if (fields_array != undefined) {
	for (field in fields_array) {

	    field2show = fields_array[field];
	    $("." + field2show).show().data('required');
	}
    }

}

function contact_us_check_mail() {
    $("input[name=email]").parent().find("span.error").remove();
    if (!/[0-9a-z_]+@[0-9a-z_^.]+\.[a-z]+/.test($("input[name=email]").val())) {
	$("input[name=email]").after(" <span class='error'>�������� email</span>");
	return false;
    }
    return true;
}

$(document).ready(function() {

    /**
     * ��������� ���� "������ �"
     */
    $("#mail_theme").change(function() {

	if ($(this).val() != "") {

	    contact_us_show_fields('mail_theme');
	    $("input[name=mail_theme]").val($("#mail_theme option:selected").text());

	    $("#mail_sub_theme").html($("#sub_theme_" + $(this).val()).html());
	    $("dd.mail_sub_theme-container > a.selectmenu").show();
	    $("#mail_sub_theme").change();
	    $(".contact_us-mail_msg").show();
	}

    });

    /**
     * ��������� ������ ���� ��� ������ �������
     */
    $(document).on('change', '#mail_sub_theme', function() {
	contact_us_show_fields($(this).val());
    });

    $('#contact_us-form').validate({
    });

    $("#contact_us-form").submit(function() {
	error = false;
	$("#contact_us-form .contact_us-must_hide:visible input:not([name=file])").each(function() {
	    if ($(this).val() == "") {
		error = true;
		$(this).parent().find(".validation-description").html('<div class="error">���� ����������� ��� ����������</div>');
	    } else {
		$(this).parent().find(".validation-description").html('');

	    }
	});

	if (error == true)
	    return false;
    });


});

$('#contact_us-form').on('change', 'select', function(){
	$('#contact_us-form select').redrawMySelect();
});