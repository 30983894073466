// <script language="javascript"><!--
function rowOverEffect(object) {
  if (object.className == 'moduleRow') object.className = 'moduleRowOver';
}

function rowOutEffect(object) {
  if (object.className == 'moduleRowOver') object.className = 'moduleRow';
}

function checkBox(object) {
  document.account_notifications.elements[object].checked = !document.account_notifications.elements[object].checked;
}

$(document).on('change', '#form_newsletter_general, #form_manufacturers_notification', function() {
	$(this).submit();
	if($('#form_newsletter_general input[name="newsletter_general"]').prop('checked') === true ){
		var create_source = 'user_account';
		if( window['yaCounter' + window.PROSKTR.yaCounterId] ) window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal(create_source);
		if(window.ga) ga('send', 'event', 'email', 'subcribe', create_source, {'nonInteraction': 1});
	}

});

$(document).on('click', '.open_popup_instruction', function() {
	$('#instructionShooseChoose-popup').fadeIn('slow');
	if($('#sizes-description .popup').length > 0){
		$('#instructionShooseChoose-popup').css({'zIndex':'1000','top':($(window).height()*0.20 + ($(window).scrollTop()-0))+'px'});
		$('#instructionShooseChoose-popup .popup').css({'left':$('#sizes-description .popup').css('left').slice(0,-2) - 5 + 'px','border' : '#756A6A solid 5px'});		
	} else {
		$('#instructionShooseChoose-popup').css({'top':'0px'});	
	}
});
//--></script>
