var customer = window.customer || new Customer();
$(function(){
	initStatistics();
	initCreateAccountMindboxForm();

	function initStatistics(){
		var shipping = '',
			payment = '';

		$('.js-click-order-send-statistics').click(function(){
			window.dataLayer = window.dataLayer || [];
			dataLayer.push({'event': 'webit_click_order'});
		});
		$('.shopping_cart').on('change','input.payment-type',function(){
			var payment_type = $(this).val();

			if(payment === payment_type){
				return true;
			}

			payment = payment_type;
			$.post('ajax_shopping_cart.php?action=get_info',function(data){
				var products = data.products,
					items = [];

				for (var i = 0; i < products.length; i++){
					var product = products[i];

					items.push({
						item_name: product.name,
						item_id: product.products_id,
						price: product.current_price,
						item_brand: product.manufacturers_name,
						item_category: product.polveshi,
						item_category2: product.cattovara_main,
						item_category3: product.cattovara_medium,
						item_category4: product.cattovara,
						item_variant: product.color,
						item_list_name: product.uriName,
						index: i+1,
						quantity: 1
					});
				}

				window.dataLayer = window.dataLayer || [];
				dataLayer.push({
					event: 'add_payment_info',
					payment_type: payment_type,
					ecommerce: {
						items: items,
					}
				});
			});
		});
		$('.shopping_cart').on('change','input[name="shipping"]',function(){
			var shipping_tier = $(this).val();
			if(shipping === shipping_tier){
				return false;
			}
			shipping = shipping_tier;
			$.post('ajax_shopping_cart.php?action=get_info',function(data){
				var products = data.products,
					items = [];

				for (var i = 0; i < products.length; i++){
					var product = products[i];

					items.push({
						item_name: product.name,
						item_id: product.products_id,
						price: product.current_price,
						item_brand: product.manufacturers_name,
						item_category: product.polveshi,
						item_category2: product.cattovara_main,
						item_category3: product.cattovara_medium,
						item_category4: product.cattovara,
						item_variant: product.color,
						item_list_name: product.uriName,
						index: i+1,
						quantity: 1
					});
				}

				window.dataLayer = window.dataLayer || [];
				dataLayer.push({
					event: 'add_shipping_info',
					shipping_tier: shipping_tier,
					ecommerce: {
						items: items,
					}
				});
			});
		});


		let ymId = 45671829;
		if ( typeof ga == "function" ) {
			ga('send', 'event', 'Shopping_Cart_Steps', 'Cart_Step1_OpenWithProducts');
			if ( window.customer.info.customer_id ) {
				ga('send', 'event', 'Shopping_Cart_Steps', 'Cart_Step1_OpenWithProducts_Loggedin');
			}
		}
		if ( typeof ym == "function" ) {
			ym(ymId, 'reachGoal', 'Cart_Step1_OpenWithProducts');
			if ( window.customer.info.customer_id ) {
				ym(ymId, 'reachGoal', 'Cart_Step1_OpenWithProducts_Loggedin');
			}
		}
		if ( $('input[name=shipping]:checked').length ) {
			if ( typeof ga == "function" ) {
				ga('send', 'event', 'Shopping_Cart_Steps', 'Cart_Step3-5_CartRecovered');
				ga('send', 'event', 'Shopping_Cart_Steps', 'Cart_Step3_CityChoose');
				ga('send', 'event', 'Shopping_Cart_Steps', 'Cart_Step4_DeliveryChoose');
				if ( $('input.payment-type:checked').length ) {
					ga('send', 'event', 'Shopping_Cart_Steps', 'Cart_Step5_PaymentChoose');
				}
			}
			if ( typeof ym == "function" ) {
				ym(ymId, 'reachGoal', 'Cart_Step3-5_CartRecovered');
				ym(ymId, 'reachGoal', 'Cart_Step3_CityChoose');
				ym(ymId, 'reachGoal', 'Cart_Step4_DeliveryChoose');
				if ( $('input.payment-type:checked').length ) {
					ym(ymId, 'reachGoal', 'Cart_Step5_PaymentChoose');
				}
			}
		}

		$('#createAccountForm').on('submit', function() {
			if ( typeof ga == "function" ) {
				ga('send', 'event', 'Shopping_Cart_Steps', 'Cart_Step2_InputNameTel');
			}
			if ( typeof ym == "function" ) {
				ym(ymId, 'reachGoal', 'Cart_Step2_InputNameTel');
			}
		});
		$('#main').on('submit', '#form-get-delivery', function() {
			if ( typeof ga == "function" ) {
				ga('send', 'event', 'Shopping_Cart_Steps', 'Cart_Step3_CityChoose');
			}
			if ( typeof ym == "function" ) {
				ym(ymId, 'reachGoal', 'Cart_Step3_CityChoose');
			}
		});
		$('#main').on('change', 'input[name=shipping]', function() {
			if ( $('input[name=shipping]:checked').length ) {
				if ( typeof ga == "function" ) {
					ga('send', 'event', 'Shopping_Cart_Steps', 'Cart_Step4_DeliveryChoose');
				}
				if ( typeof ym == "function" ) {
					ym(ymId, 'reachGoal', 'Cart_Step4_DeliveryChoose');
				}
			}
		});
		$('#main').on('change', 'input.payment-type', function() {
			if ( $('input.payment-type:checked').length ) {
				if ( typeof ga == "function" ) {
					ga('send', 'event', 'Shopping_Cart_Steps', 'Cart_Step5_PaymentChoose');
				}
				if ( typeof ym == "function" ) {
					ym(ymId, 'reachGoal', 'Cart_Step5_PaymentChoose');
				}
			}
		});
	}
});
init_sc_autocomplete();
initSCSuggestion();
var ajaxSCFormOptions = {
	dataType: 'json',
	error: function(context, xhr, e, status) {
	},
	uploadProgress: function(event, position, total, percent) {
	},
	beforeSubmit: function(formData, jqForm, options) {
		if( parse_str(options.url).action.indexOf('getCustomerCity') >= 0 ) {
			$('#form-get-login').hide();
		}
		// �������� ��������, ���� �����
		if( parse_str(options.url).action.indexOf('getShipping') >= 0 ) {
			if( formData.length ) {
				if ( window.ga ) {
					ga('ec:setAction','checkout', {
						'step': 3,
						'option': formData[0].value
					});
					ga('send', 'pageview');
				}
				if ( window['yaCounter' + window.PROSKTR.yaCounterId] )
				{
					window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal('shopping_cart_choose_city');
				}
			}
			$('#accountInfo #acc-change-btn').hide();
			$('#customerAddress, #delivery').empty();
			$('#delivery').addClass('loading');
		}

		if( parse_str(options.url).action.indexOf('getAddressFields')>=0 ) {
			$('#customerAddress').empty();
			$('#customerAddress').addClass('loading');
		}
		if( $('#'+jqForm.attr('id')+'-loading').length ) {
			$('#'+jqForm.attr('id')+'-loading').css('visibility', 'visible');
		} else {
			$('.order .ajax-loading.default').css('visibility', 'visible');
		}
		if( $(jqForm).attr('id') == 'form-get-login' ) { // ���� ��� ����� �����������, �� ������� ��� � ��������� �����, ����� ����� ����������� ������� ������ �������� �����
			if( $(jqForm).next().attr('id') == 'form-get-content' ) {
				$(jqForm).hide().next().show();
			}
			$.each( $('#form-get-delivery').serializeArray(), function(i, val) {formData.push(val); } );
		}
		if( $(jqForm).attr('id') == 'form-get-content' ) { // ���� ��� ����� �����������, �� ������� ��� � ��������� �����, ����� ����� ����������� ������� ������ �������� �����
			if( $(jqForm).prev().attr('id') == 'form-get-login' ) {
				$(jqForm).hide().prev().show();
			}
		}
		formData.push({name: 'dataType', value: options.dataType});
	},
	success: function(responseText, statusText, xhr, $form) {				
		// �������� ��� ����
		if( $form.attr('id') == 'createAccountForm' ) {
			$('[name=email_address]', $form).prop('readonly', true);
			$('[name=password]', $form).closest('.form-row').hide();
			if ( window.ga ) {
				ga('ec:setAction','checkout', {
					'step': 2,
					'option': ''
				});
				ga('send', 'pageview');
			}
			if ( window['yaCounter' + window.PROSKTR.yaCounterId] )
			{
				window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal('shopping_cart_nameemailtel_filled');
			}
		}
		
		if( $('#'+$form.attr('id')+'-loading').length ) {
			$('#'+$form.attr('id')+'-loading').css('visibility', 'hidden');
		} else {
			$('.order .ajax-loading.default').css('visibility', 'hidden');
		}
		$.each( responseText.data, function( id, data ) {
			var place = $('#' + id);
			if( place.length ) {
				place.html( data );
				place.removeClass('loading');
				// hidePaymentButtons(); // TODO
				if( place.find('.default-validate-form').length ) {
					place.find('.default-validate-form').validate();
				}
				if( place.find('.ajaxSCForm').length ) {
					place.find('.ajaxSCForm').ajaxForm(ajaxSCFormOptions);
				}
				place.find('.tooltip').tooltip_init();
				// if( place.find(':checkbox').length ) {
				// 	place.find(':checkbox').checkbox();
				// } // TODO
				place.find('.phone-11-dig').phoneMask();
				if( $.inArray('setInputFocus', responseText.options)!=-1 ) {
					place.find('input:text:first').focus();
				}
				if( $('select.delivery-fitting', place).length ) {
					$('select.delivery-fitting', place).trigger('change.fitting');
				}
				$('.with-placeholder input', place).each(function() {
					$(this)[ $(this).val().trim().length == 0 ? 'removeClass' : 'addClass' ]('edited');
				});
				if( $('.fitting-descrption-popup-opened').length ) {
					$(document).on('click', '.fitting-descrption-popup-opened', function(e) {
						e.preventDefault();
						$('#sc-primerka-popup').modal('show');
					});
					$('#sc-primerka-popup').on('click', '.btn-no-change', function(e) {
						e.preventDefault();
						$('#sc-primerka-popup').modal('hide');
					});
					$('#sc-primerka-popup').on('click', '.bnt-change', function(e) {
						e.preventDefault();
						$('#sc-primerka-popup').modal('hide');
						$('html, body').animate({ scrollTop: $('.basket-list').offset().top });
					});
				}
			}
			// BOF _gaq.push
			// EOF _gaq.push
		} );
		if( responseText.customerInfo != undefined ) {
			customer.logined = true;
			customer.info.email = responseText.customerInfo.email;
			customer.info.customer_id = parseInt(responseText.customerInfo.customers_id);
		}
		if( $.inArray('cityFocus', responseText.options)!=-1 ) {
			$('#customerCity .city-tab.active input[name=city]').focus();
		}
		if( $.inArray('delivery_date_for_time_change', responseText.options)!=-1 ) {
			$('select.delivery_date_for_time').delivery_date_for_time_change();
		}
		if( responseText.scrollTo && responseText.scrollTo != '#delivery' && responseText.scrollTo != '#customerCity' && responseText.scrollTo != '#customerAddress' ) {
			$('html, body').animate({ scrollTop: $(responseText.scrollTo).offset().top });
		} else if ( responseText.scrollTo && responseText.scrollTo == '#customerAddress' ) {
			$('html, body').animate({ scrollTop: $('#delivery').offset().top });
		}
		if ( responseText.shipping_cost != undefined ) {
			responseText.shipping_cost = responseText.shipping_cost || '-';
			$('.j-cart-delivery-total').text( responseText.shipping_cost + ' ' + window.PROSKTR.currencies.rightMin );
			$('.j-cart-full-total').text( responseText.total_formatted + ' ' + window.PROSKTR.currencies.rightMin );
			$('.j-cart-avg-prepay').text( responseText.avgFullprepayDiscountPct );
			$('.j-cart-sum-prepay').text( responseText.fullPrepayDicountSum );
			$('.j-cart-prepay-total').text( responseText.prepay_total );
		}
		var stepsList = $('.order-steps');
		init_sc_autocomplete();
		initSCSuggestion();
		toggleDontCall();
		initInput();
	}
}
$('.ajaxSCForm').each(function() {
	$(this).ajaxForm(ajaxSCFormOptions);
});
$('#customerAddress').on('change', 'input,select,textarea', function(e) {
	var dat = { 'dataType': 'json', 'forSave': {}};
	dat.form = $(this).closest('form').attr('id');
	dat['forSave'][$(this).attr('name')] = this.value;
	$.ajax({
		url: 'shopping_cart.php?action=setCustomerInfo&ajax=3',
		data: dat,
		dataType: 'json',
		type: 'POST',
		success: function(msg) {}
	});
});
$('#accountInfo').on('click', '.pass-forgotten', function(e) {
	el = this;
	form = $(el).closest('form');
	var email = form.find('input[name=email_address]').val() ? form.find('input[name=email_address]').val() : form.find('input[name=phone]').val();
	customer.sendNewPass({
			email_address: email
		},
		function( msg ) {
			$(el).closest('.row').find('.hint').html(msg);
		}
	);
	e.preventDefault();
});
customer.setCallback('onLogin', function() {
	$('#form-get-customer-city').submit();
});
$(document).on('click', '.j-s-sbmt', function(e) {
	e.preventDefault();
	$(this).closest('form').submit();
	if ( $(this).hasClass('toOrder') && !$(this).closest('form').hasClass('form-valid-error') ) {
		$('#cartList').hide();
		$('#accountInfo').hide();
		$('#order-tab').show();
		$('#delivery').empty();
		$('#customerAddress').empty();
	}
});
$.fn.delivery_date_for_time_change = function() {
	this.each(function() {
		var delivery_row = $(this).closest('.cureer-block');
		var time_select = delivery_row.find('[name='+$(this).attr('time')+']');
		var date_select = delivery_row.find('.delivery_date_for_time');
		var fitting_select = delivery_row.find('.delivery-fitting');
		time_select.empty();
		var arr = window[$(this).attr('time')];
		if (Array.isArray(arr)) {
			for (var i = 0; i < arr.length; i++) {
				if( arr[i][0]==$(this).val() ) {
					var o = new Option( arr[i][1], arr[i][1])
					$(o).html( arr[i][1] );
					time_select.append(o);
				}
			}
		}
		date_select.redrawMySelect();
		time_select.redrawMySelect();
		fitting_select.redrawMySelect();
	});
}
$('#delivery').on('change', 'select.delivery_date_for_time', function( e ) {
	$(this).delivery_date_for_time_change();
});
$('select.delivery_date_for_time').delivery_date_for_time_change();
$('#delivery').on('change', 'input[name=shipping]', function( e ) {
	var cureer_block = $(this).closest('.cureer-block');
	$('.cureer-block').removeClass('active');
	cureer_block.addClass('active');
	$('.delivery_selects').hide();
	$('.delivery_selects.select_' + $(this).val() ).show();
	cureer_block.addClass('active').siblings().addClass('hidden');
	$('.change-delivery').removeClass('hidden');
	if( this.value=='pvz_pvz' ) {
		if( cureer_block.find('input[name=pvz_id]').val().length==0 ) {
			$('#delivery .shops_map_call').trigger('click');
			this.checked = false;
			return false;
		}
	}
	$('.change-delivery').removeClass('hidden');
	$('.more-delivery').addClass('hidden');
	$(this).closest('form').attr('action', 'shopping_cart.php?ajax=1&action=setShipping.getAddressFields.getPayments') // ������ �������, ������ ����� �� ��������:(
	$(this).closest('form').submit();
	$(this).closest('div.row').siblings(':visible').fadeOut({duration: 200});
	if ( window.ga ) {
		ga('ec:setAction','checkout', {
			'step': 4,
			'option': e.target.defaultValue
		});
		ga('send', 'pageview');
	}
	if ( window['yaCounter' + window.PROSKTR.yaCounterId] )
	{
		window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal('shopping_cart_choose_delivery');
	}
});
$('#delivery').change(function(e) {
	if( e.target.name=='shipping' || $(this).find('input[name=shipping]:checked').length==0 ) {
	} else {
		$(this).attr('action', 'shopping_cart.php?ajax=1&action=setShipping') // ������ �������, ������ ����� �� ��������:(
		$(this).closest('form').submit();
	}
});

$('#delivery').on( 'click', '.shops_map_call', function(e) {
	e.preventDefault();
	var options = $(this).data('map-options');
	$('#see-map').trigger('click');
	var el = $(this);
	ymaps.ready(function() {
		$('#map-popup').shopsMap($.extend(options, {
			pvzChoose: function( properties ) {
				$('#pvz_id').val( properties.point_ident );
				$('#pvz_resource').val( properties.delivery );
				$('#pvz__city').val( properties.point_city );
				$('#pvz_iml_date').val( properties.pvz_iml_date );
				// el.text('�������� ����� ������ �� �����');
				var cureer_block = el.closest('.cureer-block');
				$('.send-value', cureer_block).text( properties.dates.send_format ); // ���� ��������
				$('.delivery-value', cureer_block).text( properties.dates.delivery_from_format ); // ���� ��������
				$('.col-2 .head', cureer_block).text( properties.dates.delivery_days_forprint ); // ��� ��������

				$('.col-3 .head', cureer_block).text( properties.cost == 0 ? '���������' : properties.cost + '�' ).data('value', properties.cost); // ����
				$('.col-3 .head-on-line', cureer_block).remove();
				if( properties.free_cost == 1 && properties.cost > 0 ) {
					$('.col-3', cureer_block).append( '<strong class="head-on-line tooltip" title="<b>���� ������ �������� ����� ���� ���������� ��� ������ ������!</b><br />��������� �������� ���������� ����� ������ ������.">��������� <div class="icon"><div class="point-pay_cache_no"></div></div></strong>' ); // ����
					$('.col-3 .head-on-line', cureer_block).tooltip_init();
				}

				if( $.inArray(properties.delivery, options.typesToFitting) != -1 ) {
					$('.fitting-holder', cureer_block).show();
					// if( $('select.delivery-fitting', cureer_block).length ) $('select.delivery-fitting', cureer_block).styler();
				} else {
					$('.fitting-holder', cureer_block).hide();
					// if( $('select.delivery-fitting', cureer_block).length ) $('select.delivery-fitting', cureer_block).styler();
				}

				$('.error', cureer_block).text('');
				$('input[name=shipping]', cureer_block).prop('checked', true);
				$('select.delivery-fitting', cureer_block).val(properties.fitting).trigger('change');
				$('input[name=shipping]', cureer_block).trigger('change');
				$('#pvz_text', cureer_block).html('<b>����� ����� ��������� �� ������:</b><br>' + properties.point_city + ', ' + properties.address + ' (���� ' + properties.delivery_name + ')');
				$('#map-popup .close').trigger('click');
			}
		}));
	});
} );

$(document).on('change', '.payment-type', function(){
	var block = $(this).closest('.payment-block');
	var li = $(this).closest('li');
	block.siblings('.payment-block').find('input.payment-type').prop('checked', false);
	block.find('input[name=paymentGroup]').prop('checked', true).trigger('change');
	$('#payments').find('.select-pay-list li').not(li).addClass('hidden');
	$('.change-payment').removeClass('hidden');
	$('.more-payments').addClass('hidden');
	toggleDontCall();
	if ( $(this).closest('.other').length ) {
		$('.more-payments').addClass('no-hide');
	} else {
		$('.more-payments').removeClass('no-hide');
	}
});

$(document).on('click', '.change-delivery', function(e){
	e.preventDefault();
	$('#delivery').find('.delivery-radio-list li').removeClass('hidden');
	$('.change-delivery').addClass('hidden');
});

$(document).on('click', '.change-payment', function(e){
	e.preventDefault();
	$('#payments').find('.select-pay-list li').removeClass('hidden');
	$('.more-payments').removeClass('hidden');
	$('.change-payment').addClass('hidden');
});

$(document).on('click', '.more-payments', function(e){
	e.preventDefault();
	if( $(this).hasClass('no-hide') ) {
		return;
	}
	if( $(this).hasClass('disabled') ) {
		$('#payments .other').slideUp();
		$(this).removeClass('disabled');
		$(this).text( $(this).data('text-hide-more') );
	} else {
		$('#payments .other').slideDown();
		$(this).addClass('disabled');		
		$(this).text( $(this).data('text-show-more') );
	}
});

$('#promo_send').submit(function(e){
	e.preventDefault();
	$.ajax({
		url: $(this).attr('action'),
		type: 'post',
		dataType: 'json',
		data: $(this).serializeArray(),
		success: function( answer ) {
			shoppingCart.setQuantityNPrice( answer );
		}
	});
});

$('.j-mindbox_promo_send').submit(function(e){
	e.preventDefault();
	$.ajax({
		url: '/ajax_shopping_cart.php?action=mindbox_coupon_action',
		type: 'post',
		dataType: 'json',
		data: $(this).serializeArray(),
		success: function( answer ) {
			if ( answer.mindboxcouponActionDone ) {
				$('.j-coupon-status-false').addClass('hidden');
				shoppingCart.setQuantityNPrice( answer );
			} else {
				$('.j-coupon-status-false').removeClass('hidden');
			}
		}
	});
});

$(document).on('click', '.cart-change-size', function(e) {
	e.preventDefault();
	if ( $(this).hasClass('disabled') ) {
		return false;
	}
	if(typeof ym==="function") {
		ym(45671829, 'reachGoal', 'Click_Shopping_Cart_ChangeButton_UpdateButton');
	}
	if(typeof ga==="function") {
		ga('send', 'event', 'Shopping_Cart', 'Click_Shopping_Cart_ChangeButton_UpdateButton');
	}

	var editSizeProductLayerEl = $('#change-item-modal .modal-body');
	var tdInfo = $(this).closest('.product-small-preview');
	var image = $(this).data('image');
	var currentQty = $(this).data('qty');
	var sendData = {
		'id': [],
		'products_id': [],
		'clicked': '��������',
		'action': 'get_info'
	};
	sendData['id'][ tdInfo.data('id') ] = {};
	sendData['id'][ tdInfo.data('id') ][ 1 ] = tdInfo.data('id');
	sendData['products_id'][0] = tdInfo.data('id');
	sendData['products_id'][0] = tdInfo.data('id');
	$.ajax({
		url: '/ajax_shopping_cart.php',
		type: 'POST',
		dataType: 'json',
		data: sendData,
		success: function( msg ) {
			var currentQty = msg.product_showcase_quantity || 1,
				attributesId = msg.attributes_id;

			$('.js-cart-change-size-opener', tdInfo).trigger('click');
			editSizeProductLayerEl.html( $.render[ 'edit-size-product-layer' ].render( msg ) );
			$('form[name="cart_quantity"]').off('change');
			$('form[name="cart_quantity"]').on('change', '.js-size', function () {
				var select_qty = $(this).closest('form').find('.change_products_qty'),
					checkedAttributeId = $('form[name="cart_quantity"] .js-size:checked').attr('data-value');

				select_qty.empty();
				var max_qty = Math.min(parseInt($(this).closest('form').find('.js-size:checked').attr('data-quantity')), 10);
				for (var i = 1; i <= max_qty ; i++) {
					select_qty.append('<option value="'+i+'">'+i+"</option>");
				}
				if(max_qty >= currentQty) {
					select_qty.val(currentQty);
				}else{
					select_qty.val(max_qty);
				}
				setCheckedSize(checkedAttributeId)
				select_qty.redrawMySelect();
				$('form[name="cart_quantity"] .j-select-size-type').redrawMySelect();
				function setCheckedSize(checkedAttributeId){
					$('form[name="cart_quantity"] .js-size').attr('data-checked',0);
					$('form[name="cart_quantity"] .js-size[data-value="'+checkedAttributeId+'"]').attr('data-checked',1);
				}
			});
			var isOneSize=$('form[name="cart_quantity"] .change_products_size').length;

			if(isOneSize){
				var select_qty = $('form[name="cart_quantity"] .change_products_qty');
				select_qty.empty();
				var max_qty = Math.min(parseInt($('form[name="cart_quantity"] .change_products_size').find(":selected").attr('qty')), 10);
				for (var i = 1; i <= max_qty ; i++) {
					select_qty.append('<option value="'+i+'">'+i+"</option>");
				}
				if(max_qty >= currentQty) {
					select_qty.val(currentQty);
				}else{
					select_qty.val(max_qty);
				}
				select_qty.redrawMySelect();
				$('form[name="cart_quantity"] .change_products_size').redrawMySelect();
			}
			$('#change-item-modal select').selectpicker('refresh');
			$('form[name=cart_quantity]').on('change', '.change_products_qty', function() {
				$(this).closest('form').find('.change_products_size').attr('qty', $(this).val());
			});
			$('form[name=cart_quantity] .js-size').trigger('change');
			$('.size-img').attr('src', image);
			$(editSizeProductLayerEl).on('submit', 'form', function(e){
				e.preventDefault();
				var data = $(this).serializeArray(),
					checkedSizeId = $('.js-size:checked', $(this)).attr('data-selected-id');

				$.ajax({
					url: $(this).attr('action'),
					type: 'post',
					dataType: 'json',
					data: data,
					success: function( answer ) {
						shoppingCart.setQuantityNPrice( answer );
					}
				});
			});
		}
	});
});

//�������� ������ � �������
$('.js-basket-list').on('click','.product-small-preview .del',function (e) {
	e.preventDefault();

	var data = {};

	data.products_id = $(this).closest('form').find('[name="products_id[]"]').val();
	ifConfirm({
		header: "�������� ������",
		message: "�� ����� ������ ������� ��������� �����? �������� �������� ����� ����������",
		button: "�����������"
	}, function(){
		shoppingCart.deleteProduct(data);
	});
});

function initSCSuggestion() {
	var 
		token = "483fd2413718ec3fb98454a0256d84481dd60fa5 ",
		type  = "ADDRESS",
		$street = $("#street-dadata");
		$house = $("#house-dadata");
		if ( !$street.length || !$house.length ) {
			return false;
		}
	if( $('#city-dadata').length == 0 ) {
		return;
	}

	if (typeof $street === "object" && typeof $street.suggestions === "function") {
		$street.suggestions({
			serviceUrl: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
			token: token,
			type: type,
			hint: false,
			bounds: "street",
			constraints: {
				locations: {
					city: $('#city-dadata').val(),
				},
			},
			onSelect: function (suggestion) {
				onSelectSuggestion(suggestion);
			},
		});
	}

	if (typeof $house === "object" && typeof $house.suggestions === "function") {

		$house.suggestions({
			serviceUrl: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
			token: token,
			type: type,
			hint: false,
			bounds: "house",
			constraints: $street,
			onSelect: function (suggestion) {
				onSelectSuggestion(suggestion);
			},
		});
	}

	setTimeout( function(){
		if ( $("#house-dadata").length && $("#street-dadata").val() != '' ) {
			$("#house-dadata").suggestions().fixData();
		}
	}, 1000);
};

function onSelectSuggestion(suggestion) {
	if ( suggestion.data.postal_code != null && $('#customerAddress input[name=index]').length )
	{
		$('#customerAddress input[name=index]').val( suggestion.data.postal_code ).trigger('change');
	}
	else
	{
		$('#customerAddress input[name=index]').val('');
	}
	if ( suggestion.data.house != null && $('#customerAddress input[name=house]').length )
	{
		$('#customerAddress input[name=house]').val( suggestion.data.house ).trigger('change');
	}
	else
	{
		$('#customerAddress input[name=house]').val('');
	}
	if ( suggestion.data.block != null && $('#customerAddress input[name=housing]').length ) {
		$('#customerAddress input[name=housing]').val( suggestion.data.block ).trigger('change');
	}
	else
	{
		$('#customerAddress input[name=housing]').val('').trigger('change');
	}
}

$(window).scroll(function(){
	var currentScroll = $(window).scrollTop();
	var staticCostPos = $('.basket-total.static').offset();
	var productsPos = $('.product-panel-section').offset();
	var fixedEl = $('.fixed-basket-totals .basket-total');
	if ( (staticCostPos && (currentScroll - staticCostPos.top < 0)) || $(window).outerWidth() <= 1024 )
	{
		$('.fixed-basket-totals').addClass('hidden');
	}
	else
	{
		$('.fixed-basket-totals').removeClass('hidden');
	}
	var btnStaticEl = $('#accountInfo .toOrder:visible').length ? $('#accountInfo .toOrder') : $('.ordering-header');
	if( btnStaticEl.length ) {
		var btnStaticPos = btnStaticEl.offset();
		if ( currentScroll - btnStaticPos.top + $(window).height() - 40 < 0 ) {
			$('.fixed-basket-toOrder').show();
		} else {
			$('.fixed-basket-toOrder').hide();
		}
	}
	if ( productsPos && (currentScroll + fixedEl.outerHeight() > productsPos.top) ) {
		fixedEl.css('top', (productsPos.top - currentScroll - fixedEl.outerHeight()) + 'px');
	} else {
		fixedEl.css('top', '');
	}
});
$(window).trigger('scroll');
$(document).on('click', '.scroll-to-order', function(){
	var btnStaticEl = $('#accountInfo .toOrder:visible').length ? $('#accountInfo') : $('.ordering-header');
	$('html, body').animate({ scrollTop: btnStaticEl.offset().top });
});

$('#delivery').on('click', '.del-all-product-label', function( e ) {
	e.preventDefault();
	data = {};
	var sendData = { products_id: [], cart_delete: [] };
	$('.del-product-label').each(function(){
		sendData.products_id.push( $(this).attr('products_id') );
		sendData.cart_delete.push( $(this).attr('products_id') );
	});
	var deleteProductCallback = typeof shoppingCart.options.deleteProductCallback == 'function' ? shoppingCart.options.deleteProductCallback( sendData ) : shoppingCart.deleteProductCallback( sendData ) ;
	$.ajax({
		url: '/ajax_shopping_cart.php?action=update_product&ajax=1',
		type: 'POST',
		data: sendData,
		dataType: 'json',
		success: deleteProductCallback
	});
});
function toggleDontCall() {
	var checkedPaymentEl = $('#payments .payment-block .select-pay-list input:radio:checked');
	var checkedPayment = $(checkedPaymentEl).val();
	var currentVal = $('.dontCallLabel').data('current');
	if ( checkedPaymentEl.length && checkedPayment != 'cod' ) {
		$('.dontCallLabel').css('display', 'block');
		if ( currentVal == 'off' ) {
			$('input[name=dontCall][value=off]').prop('checked', true);
		} else {
			$('input[name=dontCall][value=on]').prop('checked', true);
		}
	} else if ( $('input[name=dontCall]').data('showdontcall') == 1 ) {
		$('.dontCallLabel').css('display', 'block');
		if ( currentVal == 'off' ) {
			$('input[name=dontCall][value=off]').prop('checked', true);
		} else {
			$('input[name=dontCall][value=on]').prop('checked', true);
		}
	} else {
		$('.dontCallLabel').css('display', 'none');
		$('input[name=dontCall][value=off]').prop('checked', true);
	}
}
toggleDontCall();
$(document).on('change', 'input[name=dontCall]', function(){
	$('.dontCallLabel').data('current', $('input[name=dontCall]:checked').val());
});
$(document).on('submit', '#customerAddressForm', function(e) {
	if ( $(this).hasClass('form-valid-error') ) {
		return false;
	}
	$('[type="submit"]', this).prop('disabled', 'disabled').addClass('loading');
	$('input:submit', this).closest('label').addClass('loading-in-btn');
});

if( $('.fitting-descrption-popup-opened').length ) {
	$(document).on('click', '.fitting-descrption-popup-opened', function(e) {
		e.preventDefault();
		$('#sc-primerka-popup').modal('show');
	});
	$('#sc-primerka-popup').on('click', '.btn-no-change', function(e) {
		e.preventDefault();
		$('#sc-primerka-popup').modal('hide');
	});
	$('#sc-primerka-popup').on('click', '.bnt-change', function(e) {
		e.preventDefault();
		$('#sc-primerka-popup').modal('hide');
		$('html, body').animate({ scrollTop: $('.basket-list').offset().top });
	});
}

/**
 * ��������� ���������� ���� �� ����������� � ��� ���������� ����� �������
 * @param paramsObj {header:"���������",message:"����� ���������",button:"�����������"}
 * @param callback
 */
function ifConfirm(paramsObj, callback) {
	if (!paramsObj) {
		throw "����� ��� ����������";
	}

	var modal = $('#confirm-modal');

	if (!modal) {
		throw "�� �������� ������ � ��������� �����";
	}

	if (paramsObj.header) {
		$('h4', modal).html(paramsObj.header);
	}

	if (paramsObj.message) {
		$('.inform-text', modal).html(paramsObj.message);
	}

	if (paramsObj.button) {
		$('button[type="submit"]', modal).html(paramsObj.button);
	}

	var form = $('form', modal).off();

	if (typeof callback === 'function') {
		$('button[type="submit"]', form).fadeIn();
		$(form).submit(function () {
			callback();
			$(modal).modal('hide');
			return false;
		});
	} else {
		$('button[type="submit"]', form).fadeOut();
	}

	$(modal).modal('show');
}

// ������������� ������ start
$(function(){
	$('.product-panel-section a').click(function(){
		if($('.product-panel-section').hasClass('close-list')){
			$('.product-panel-section').removeClass('close-list');
			$('.product-panel-section .open-line').toggleClass('none');
			return false;
		}
	});
	initSlider();
	initBasketList();
	$('.product-panel-section .open-line').click(function(){
		$('.product-panel-section').toggleClass('close-list');
		$('.product-panel-section .open-line').toggleClass('none');
	});

	function initSlider() {
		$('.product-list-slider').slick({
			slidesToShow: 4,
			infinite: false,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						arrows: false
					}
				},
				{
					breakpoint: 800,
					settings: {
						arrows: false,
						slidesToShow: 4
					}
				},
				{
					breakpoint: 768,
					settings: {
						arrows: false,
						slidesToShow: 4
					}
				},
				{
					breakpoint: 680,
					settings: {
						arrows: false,
						slidesToShow: 4
					}
				},
				{
					breakpoint: 560,
					settings: {
						arrows: false,
						slidesToShow: 3
					}
				},
				{
					breakpoint: 400,
					settings: {
						arrows: false,
						slidesToShow: 2
					}
				}
			]
		});
	}
	function initBasketList(){
		$('.js-basket-list').on('click','.js-cart-change-size',function(){
			if(typeof ym === "function") {
				ym(45671829, 'reachGoal', 'Click_Shopping_Cart_ChangeButton');
			}
			if(typeof ga === "function") {
				ga('send', 'event', 'Shopping_Cart', 'Click_Shopping_Cart_ChangeButton');
			}
		});
	}
});
// ������������� ������ end

$(document).on('change', '.customers-presents select[name=customers_bonus]', function() {
	var div = $(this).closest('.customers-presents');
	// div.find('.ajax-success').hide();
	div.find('.ajax-loader').show();
	$.ajax({
		type: 'POST',
		data: {'choosen_bonus': $(this).val()},
		url: 'ajax_shopping_cart.php?action=set_bonus',
		dataType: 'json',
		context: this,
		success: function(msg) {
			$('form[name=cart_quantity]').submit();
		}
	});
});

// function CreateAccountMindboxFormSubmit( id ) {
function initCreateAccountMindboxForm() {
	if ( $('#createAccountFormMindboxHolder').length == 0 ) {
		return;
	}
	$('#createAccountFormMindboxHolder').on('submit', 'form', function(e){
		e.preventDefault();
		let hasEmpty = false;
		let $form = $(this);
		let $btn = $('[type="submit"]', $form);
		// let $form = $('#' + id);
		// let $btn = $('.btn-submit', $form);	

		if ( $btn.hasClass('disabled') ) return;
		$btn.addClass('disabled').addClass('loading');

		$('input[type=text]', $form).each(function(){
			if ( $(this).val() == '' ) {
				$(this).addClass('error');
				hasEmpty = true;
			}
		});
		if ( hasEmpty ) {
			$btn.removeClass('disabled').removeClass('loading');
			return;
		}
		$.ajax({
			url: '/ajax_mindbox.php',
			type: 'POST',
			data: $form.serializeArray(),
			dataType: 'json',
			context: this,
			success: function( msg ) {
				$('#createAccountFormMindboxHolder').html(msg.html);
				if ( msg.login ) {
					if ( PROSKTR.content == 'shopping_cart' && msg.count_contents_before != msg.count_contents_after ) {
						window.location.reload();
					}
					$('form#createAccountForm').ajaxForm(ajaxSCFormOptions);
					$('form#createAccountForm').submit();
				}
			}
		});
	});
}

$('.not-empty-cart').on('change', 'input[name=paymentonLine]', function() {
	$('#cart-loading').removeClass('hidden');
	let paymentType = $(this).val();
	let $bonusHolder = $('#bonus-block');
	$.ajax({
		type: 'POST',
		data: {'paymentType': paymentType},
		url: 'ajax_shopping_cart.php?action=setMindboxPaymentType',
		dataType: 'json',
		context: this,
		success: function(msg) {
			if ( msg.mindboxPrepayDiscount ) {
				$('.j-prepay-discount').text(msg.mindboxPrepayDiscount).closest('dl').removeClass('hidden');
			} else {
				$('.j-prepay-discount').text('0').closest('dl').addClass('hidden');
			}
			if ( msg.mindboxCashback ) {
				$('.j-cart-cashback').text(msg.mindboxCashback).closest('dl').removeClass('hidden');
			} else {
				$('.j-cart-cashback').text('0').closest('dl').addClass('hidden');
			}
			if ( msg.quarterOfTotal ) {
				$('.j-cart-quarterOfTotal').text(msg.quarterOfTotal);
			}
			$('.j-cart-bonus').text('0').closest('dl').addClass('hidden');
			if ( msg.total_with_shipping ) {
				$('.j-cart-full-total').text(msg.total_with_shipping + ' ' + window.PROSKTR.currencies.rightMin);
				if ( $('.j-action-yes').length > 0 ) {
					if ( msg.total_with_shipping > 11000 ) {
						$('.j-action-yes').removeClass('hidden');
						$('.j-action-no').addClass('hidden');
					} else {
						$('.j-action-yes').addClass('hidden');
						$('.j-action-no').removeClass('hidden');
					}
				}
			}
			if ( msg.mindboxBonusLimit ) {
				let currentVal = $('input[name=useMindboxBonusesValueInput]', $bonusHolder).val();
				if ( msg.mindboxBonusLimit < currentVal ) {
					$('input[name=useMindboxBonusesValueInput]', $bonusHolder).val(msg.mindboxBonusLimit);
					$('input[name=useMindboxBonusesValue]', $bonusHolder).val(msg.mindboxBonusLimit);
				}
				$('input[name=useMindboxBonusesValueInput]', $bonusHolder).prop('max', msg.mindboxBonusLimit);
				$('.j-bonus-limit', $bonusHolder).text(msg.mindboxBonusLimit).data('limit', msg.mindboxBonusLimit);
				$('.j-bonus-total', $bonusHolder).text(msg.mindboxBonusTotalStr);
				$bonusHolder.addClass('remove');
				if ( currentVal > 0 ) {
					$('.j-set-bonus-value').trigger('click');
					return;
				}
			} else {
				$bonusHolder.addClass('hidden');
				$('input[name=useMindboxBonusesValue]', $bonusHolder).val(0);
			}
			if ( msg.problemList ) {
				$('.j-to-order').addClass('hidden');
				$('.j-problems-list').html('');
				for ( let index = 0; index < msg.problemList.length; index++ ) {
					const element = msg.problemList[index];
					$('.j-problems-list').append('<div>' + msg.problemList[index] + '</div>');
				}
				$('.j-min-order').removeClass('hidden');
			} else {
				$('.j-min-order').addClass('hidden');
				$('.j-to-order').removeClass('hidden');
			}
			$('#cart-loading').addClass('hidden');
		}
	});
});

$('.not-empty-cart').on('click', '.j-set-bonus-value', function() {
	$('#cart-loading').removeClass('hidden');
	let paymentType = $('input[name=paymentonLine]:checked').val();
	let $bonusHolder = $('#bonus-block');
	let bonusValue = $('input[name=useMindboxBonusesValueInput]', $bonusHolder).val();
	let bonusLimit = $('input[name=useMindboxBonusesValueInput]', $bonusHolder).prop('max');
	if ( bonusValue > bonusLimit ) {
		bonusValue = bonusLimit;
	}
	if ( bonusValue < 0 ) {
		bonusValue = 0;
	}
	$('input[name=useMindboxBonusesValue]', $bonusHolder).val(bonusValue);
	$.ajax({
		type: 'POST',
		data: {'paymentType': paymentType, 'bonusValue': bonusValue},
		url: 'ajax_shopping_cart.php?action=setMindboxBonusValue',
		dataType: 'json',
		context: this,
		success: function(msg) {
			if ( msg.mindboxPrepayDiscount ) {
				$('.j-prepay-discount').text(msg.mindboxPrepayDiscount).closest('dl').removeClass('hidden');
			} else {
				$('.j-prepay-discount').text('0').closest('dl').addClass('hidden');
			}
			if ( msg.mindboxCashback ) {
				$('.j-cart-cashback').text(msg.mindboxCashback).closest('dl').removeClass('hidden');
			} else {
				$('.j-cart-cashback').text('0').closest('dl').addClass('hidden');
			}
			if ( msg.quarterOfTotal ) {
				$('.j-cart-quarterOfTotal').text(msg.quarterOfTotal);
			}
			if ( msg.total_with_shipping ) {
				$('.j-cart-full-total').text(msg.total_with_shipping + ' ' + window.PROSKTR.currencies.rightMin);
			}
			if ( bonusValue ) {
				$('.j-cart-bonus').text(bonusValue).closest('dl').removeClass('hidden');
			} else {
				$('.j-cart-bonus').text('0').closest('dl').addClass('hidden');
			}
			if ( msg.problemList ) {
				$('.j-to-order').addClass('hidden');
				$('.j-problems-list').html('');
				for ( let index = 0; index < msg.problemList.length; index++ ) {
					const element = msg.problemList[index];
					$('.j-problems-list').append('<div>' + msg.problemList[index] + '</div>');
				}
				$('.j-min-order').removeClass('hidden');
			} else {
				$('.j-min-order').addClass('hidden');
				$('.j-to-order').removeClass('hidden');
			}
			$('#cart-loading').addClass('hidden');
		}
	});
});

$('.not-empty-cart').on('click', '.j-bonus-limit', function() {
	let bonusVal = $(this).data('limit');
	let $bonusHolder = $('#bonus-block');
	$('input[name=useMindboxBonusesValueInput]', $bonusHolder).val(bonusVal);
	$('.j-set-bonus-value', $bonusHolder).trigger('click');
});
