$(function(){
	initGoUp();
	initSmartApp();

	function initGoUp(){
		$('.js-go-up').click(function(){
			$('html,body').animate({scrollTop: '0px'}, 600);
		});
	}

	function initSmartApp(){
		if(window.innerWidth<1025){
			var isAndroid = navigator.userAgent.split('Android').length>1,
				isShowSmartApp = (!localStorage.getItem('hideSmartApp')) && isAndroid;

			if(isShowSmartApp){
				$('.js-smart-app').css("display", "flex")
					.hide()
					.fadeIn();
				$('.js-smart-app-close').click(function(){
					$('.js-smart-app').fadeOut();
					localStorage.setItem('hideSmartApp', 1);
				});
			}
		}
	}
});