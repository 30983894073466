function InitShoeFinder() {
	if ( $('.j-shoe-finder').length == 0 ) {
		return;
	}

	let $finder = $('.j-shoe-finder');
	let $list = $('.j-shoe-list');
	let $sublist = $('.j-shoe-sublist');
	let $breadcrumbs = $('.j-finder-breadcrumbs');
	let $breadcrumbsWrapper = $('.js-breadcrumbs-holder');

	let data = $finder.data('tree');
	let root = data.root;
	let nodes = data.nodes;
	let productsData = $list.data('products');
	let crumbs = new Array();
	crumbs.push({
		'id': root,
		'text': '����� ���������'
	});

	if ( root > 0 && nodes[root] ) {
		RenderNode(root);
		RenderBreadcrumbs(root);
	}

	$finder.on('click', '.j-finder-a', function() {
		let nodeId = $(this).data('nextQ');
		let crumb = {
			'id': nodeId,
			'text': $(this).data('textMini'),
		};
		window.scrollTo({top: 0, behavior: 'smooth'});
		$finder.html('');
		$finder.addClass('loading');
		crumbs.push(crumb);

		RenderNode(nodeId);
		RenderBreadcrumbs(nodeId);
		RenderBackBlock(nodeId);
	});

	$finder.on('click', '.j-finder-back-btn', function() {
		let nodeId = $(this).data('id');
		$finder.html('');
		$finder.addClass('loading');

		RenderNode(nodeId);
		RenderBreadcrumbs(nodeId);
		RenderBackBlock(nodeId);
	});

	$finder.on('click', '.j-finder-home-btn', function() {
		$finder.html('');
		$finder.addClass('loading');

		RenderNode(root);
		RenderBreadcrumbs(root);
		RenderBackBlock(root);
	});

	$breadcrumbs.on('click', '.j-breadcrumb-link', function() {
		let nodeId = $(this).data('id');

		$finder.html('');
		$finder.addClass('loading');

		RenderNode(nodeId);
		RenderBreadcrumbs(nodeId);
		RenderBackBlock(nodeId);
	});

	function RenderNode( nodeId ) {
		let node = document.createElement('div');
		node.className = 'finder-node';
		
		if ( nodes[nodeId].text ) {
			$('.finder-done').addClass('hidden');
			let question = document.createElement('div');
			question.className = 'finder-q';
			question.innerHTML = nodes[nodeId].text;
			node.append(question);
		} else {
			$('.finder-done').removeClass('hidden');
		}
		
		if ( nodes[nodeId].routes.length ) {
			let answers = document.createElement('div');
			answers.className = 'finder-a-holder repeat-' + nodes[nodeId].routes.length;
			
			for ( let i = 0; i < nodes[nodeId].routes.length; i++ ) {
				let route = nodes[nodeId].routes[i];
				let answer = document.createElement('div');
				answer.className = 'finder-a j-finder-a';
				if ( route.image_url != '' ) {
					let innerHtml = '<img src="' + route.image_url + '">' + route.route_text;
					answer.innerHTML = innerHtml;
				} else {
					answer.innerHTML = route.route_text;
				}
				answer.dataset.nextQ = route.to_node_id;
				answer.dataset.currentQ = nodeId;
				answer.dataset.textMini = route.route_text_mini;
				answers.append(answer);
			}
			node.append(answers);
		}

		$finder.removeClass('loading');
		$finder.append(node);

		$list.html('');
		$('.j-shoe-list-head').addClass('hidden');
		if ( nodes[nodeId].products.length > 0 && nodeId != root ) {
			$list.addClass('loading');
			let productsList = new Array();
			for ( let index = 0; index < nodes[nodeId].products.length; index++) {
				let element = nodes[nodeId].products[index];
				productsList.push(productsData[element]);
			}

			let productsHtml = $.render['listing_product'].render(productsList, {currencies: window.PROSKTR.currencies });
			$list.append(productsHtml);
			$list.removeClass('loading');
			$('.j-shoe-list-head').removeClass('hidden');
			$('.j-shoe-list-head').addClass('loading');
			setTimeout(() => {
				$('.j-shoe-list-head').removeClass('loading');
			}, "1000");
		}

		$sublist.html('');
		$('.j-shoe-sublist-head').addClass('hidden');
		if ( nodes[nodeId].subproducts.length > 0 && nodeId != root ) {
			$sublist.addClass('loading');
			let productsList = new Array();
			for ( let index = 0; index < nodes[nodeId].subproducts.length; index++) {
				let element = nodes[nodeId].subproducts[index];
				productsList.push(productsData[element]);
			}

			let productsHtml = $.render['listing_product'].render(productsList, {currencies: window.PROSKTR.currencies });
			$sublist.append(productsHtml);
			$sublist.removeClass('loading');
			$('.j-shoe-sublist-head').removeClass('hidden');
		}
	}

	function RenderBackBlock( nodeId ) {
		if ( crumbs.length < 2 ) {
			return;
		}

		let $holder = $('.finder-node');
		let backBlock = document.createElement('div');
		backBlock.className = 'finder-back';

		if ( crumbs.length > 2 ) {
			let homeBtn = document.createElement('div');
			homeBtn.className = 'finder-back-btn j-finder-home-btn btn';
			homeBtn.innerHTML = '� ������';
			backBlock.append(homeBtn);
		}

		let backId = 0;
		let findCurrent = false;
		for ( let i = crumbs.length - 1; i >= 0; i-- ) {
			let e = crumbs[i];
			if ( findCurrent ) {
				backId = e.id;
				break;
			} else {
				findCurrent = e.id == nodeId;
			}
		}
		let backBtn = document.createElement('div');
		backBtn.className = 'finder-back-btn j-finder-back-btn btn';
		backBtn.dataset.id = backId;
		backBtn.innerHTML = '�����';
		backBlock.append(backBtn);

		$holder.append(backBlock);
	}

	function RenderBreadcrumbs( nodeId ) {
		$breadcrumbs.html('');
		let findCurrent = false;
		for ( let i = crumbs.length - 1; i >= 0; i-- ) {
			let e = crumbs[i];
			if ( !findCurrent ) {
				findCurrent = e.id == nodeId;
			}
			if ( findCurrent ) {
				let crumb = document.createElement('li');
				crumb.className = 'j-breadcrumb-link';
				crumb.dataset.id = e.id;
				crumb.innerHTML = '<span class="j-finder-home-btn">' + e.text + '</span>';
				$breadcrumbs.prepend(crumb);
			} else {
				crumbs.pop();
			}
		}
		if($breadcrumbs.width()>$breadcrumbsWrapper.width()){
			$breadcrumbsWrapper.addClass('long-text');
		}else{
			$breadcrumbsWrapper.removeClass('long-text');
		}
	}
}

InitShoeFinder();