// http://javascript-minifier.com/
(function () {
  if (!$("form.quick_find").length) {
    return false;
  }

  var cache = new Cache(),
    historySearch = new WorkFromHistory(),
    searchListMaxLength = 15; //������������ ���-�� ����� ���������

  $('form.quick_find').each(function (index, currentForm) {
    var term = '',
      categoryId = $('[name="keywords"]', currentForm).data('main-category-id') || undefined,
      categoryName = $('[name="keywords"]', currentForm).data('main-category-name') || undefined,
      addSearchHeader = !categoryId ? function () {} : function (data, term) {
        data.unshift({
          value: term,
          categoryId: categoryId,
          categoryName: categoryName,
          count: '-'
        });
      };

    $('input[name=keywords]', this).autocomplete({
      minLength: 3,
      // appendTo: "#search_result",
      source: function (request, response) {
        term = request.term.toLowerCase();

        var data = cache.get(term);
        
        if (data !== false) {
          addSearchHeader(data, term);
          if (data.length > searchListMaxLength) {
            data = data.slice(0, searchListMaxLength - 1);
          }
          response(data);
          return;
        }

        $.getJSON("/search.php?type=ajax_search", {'term': term.substr(0, 1)}, function (data, status, xhr) {
          cache.set(term.substr(0, 1), data);
          data = cache.get(term);
          if (data !== false) {
            addSearchHeader(data, term);
            if (data.length > searchListMaxLength) {
              data = data.slice(0, searchListMaxLength - 1);
            }
            response(data);
            return;
          }
        });
      },
      select: function (event, ui) {
        if (ui.item != undefined) {
          // this.value = $(event.srcElement).closest('.ui-menu-item').find('span.result').text();
          this.value = ui.item.value;
          $('[name="cPath"]',currentForm).val(ui.item.categoryId).attr('value',ui.item.categoryId);
        }
        $(currentForm).submit();
        if (window.outerWidth <= 1024) {
          $('form.quick_find .div-loading').show();
        }
        return false;
      },
      change: function (event, ui) {
        if (ui.item == null) {
          var ul = $("#autocomplete").autocomplete("widget").show();
          if (ul.find('li.ui-menu-error').length == 0) {
            ul.prepend("<li class='ui-menu-error'><span>�������� �� ������</span></li>");
          }
        }
      },
      menufocus: function (event, ui) {
      },
    }).data("ui-autocomplete")._renderItem = function (ul, item) {
      if (!item.categoryId) {
        return $("<li class='ui-menu-item '>")
          .append("<a class='suggestion ui-corner-all'><span class='result'>" + makeBoldText(item.value, term) + "</span><span class='result-count'>" + item.count + "</span></a>")
          .appendTo(ul);
      }else{
        return $("<li class='ui-menu-item '>")
          .append('<a ' +
            'class="suggestion ui-corner-all" ' +
            '<span class="result">' + term + ' � ��������� <span class="category-name">'+item.categoryName+'</span></span>' +
            '</a>')
          .appendTo(ul);
      }
    };

    $(currentForm).submit(search.bind(this,historySearch));

    $('input[name=keywords]', this).keyup(function (event) {
      if ($('#clear-search>a').is(':hidden') && !$('form[name=quick_find] .div-loading').is(':visible') && this.value.length > 0) {
        $('#clear-search>a').fadeIn();
      } else {
        if ($('#clear-search>a').is(':visible') && this.value.length == 0) {
          $('#clear-search>a').fadeOut();
        }
      }

      //���� ������ ��� ����� � ������ - ���������� ���� �� ������
      if (this.value.length === 0) {
        youAlreadySearched.apply(this, [currentForm]);
      }

      if (event.key === 'Enter' && $(this).val().length()>2) {
        currentForm.submit();
      }
    }).focus(youAlreadySearched.bind($('input[name=keywords]', this)[0], currentForm));

    $('#clear-search>a').click(function () {
      $('input[name=keywords]', this).val('');
      $('input[name=keywords]', this).focus();
      $(this).fadeOut();
      return false;
    });

  });

  /**
   * �������� ��� ������
   * @param historySearch
   */
  function search(historySearch) {

    $('#clear-search>a').hide();

    if (window.outerWidth <= 1024) {
      $('form.quick_find .div-loading').show();
    }

    if ($('input[name=keywords]', this)[0]) {
      var searchText = $('input[name=keywords]', this)[0].value;

      if(searchText.length<3){
        return false;
      }
      historySearch.addFromHistoryList(searchText.toLowerCase());
    }
  }

  /**
   * ��������� ���������� ��� ������ � �������� ������
   * @returns {boolean}
   */
  function youAlreadySearched(currentForm) {
    var historyList = historySearch.getHistoryList(),
      ul = $(this).autocomplete("widget").empty();

    if (this.value.length || !historyList.length) {
      return false;
    }

    var me = this,
      buttonClear = "<button class='recent-search' id='clear-recent-search' use='id'>��������</button>";

    $("<li class='old-search'>").append("<span class='title'>�� ��� ������:</span>" + buttonClear).appendTo(ul);

    //�������� ��� ������� ������
    $('#clear-recent-search').click(function (event) {
      event.preventDefault();
      historySearch.clearHistoryList();
      youAlreadySearched.apply(me, [currentForm]);
    });

    for (var i = historyList.length - 1; i >= 0; i--) {
      var searchText = historyList[i];
      $("<li class='old-search'>").append(
        "<a class='suggestion ui-corner-all'><span class='result'>" + searchText + "</span></a>"
        + "<button class='recent-search recent-search-delete' use='class:recent-search-delete' data-search-text='"
        + (searchText + '').replaceAll('"', '\"') + "'>�������</button>"
      ).appendTo(ul)
    }

    //������� ������� ������
    $('.recent-search-delete', ul).click(function (event) {
      event.preventDefault();

      var searchText = $(this).data('search-text');

      if (!searchText) {
        return false;
      }

      historySearch.deleteFromHistoryList(searchText);
			youAlreadySearched.apply(me, [currentForm]);
    });

    //������� �� ������� ������
    $('li.old-search a', ul).click(function () {
      me.value = $(this).text();
      ul.fadeOut();
      currentForm.submit();
    });

    setTimeout(function () {
      ul.show();
    }, 300);
  }

  /**
   * ������ ����� ������ ����� ���������, ���� � ���� ������
   * ���� �������� ������ ������� - ������ ��� ���������
   * @param text
   * @param context
   */
  function makeBoldText(text, context) {
    if (!context) {
      return text;
    }

    text = (text + '').toLowerCase();
    context = (context + '').toLowerCase();
    var slices = text.split(context);

    return '<b>' + slices.join('</b>' + context + '<b>') + '</b>';
  }

  /**
   * ��� ���������� ���������� �������� � �������
   */
  function Cache() {
    var cache = {},
        _localStorageVarName = 'searchListCache';

    initCache();

    this.set = function(termString,valueArr){
      if (termString in cache || typeof valueArr.slice !== "function") {
        return false;
      }
      //������ ������ �� ��������� ������� �������� ����� �������(� js ������ ��� ������)
      cache[termString] = valueArr.slice(0);
      sessionStorage.setItem(_localStorageVarName, JSON.stringify(cache));
      return true;
    }
    this.get = function (search) {
      if (search in cache) {
        //����� ������ ������ - ������ ������ ������ �� ������ ���� return cache[search]; - ����� ����� ��������� ������ � cache
        return cache[search].slice(0);
      }

      search = search.split(' ');
      var term_tmp,
          data = [];

      for (var j = search[0].length - 1; j >= 0; j--) {
        term_tmp = search[0].substr(0, j);
        if (term_tmp in cache) {
          for (var i = 0, size = cache[term_tmp].length; i < size; i++) {
            if (cache[term_tmp][i].value.toLowerCase().indexOf(search[0]) !== -1) data.push(cache[term_tmp][i]);
          }
          break;
        }
      }

      for (var i = search.length - 1; i > 0; i--) {
        for (var k = data.length - 1; k >= 0; k--) {
          if ((strpos = data[k].value.toLowerCase().indexOf(search[i])) === -1) {
            data.splice(k, 1);
          } else {
            if (strpos != 0 && data[k].value.toLowerCase().indexOf(' ' + search[i]) === -1) {
              data.splice(k, 1);
            }
          }
        }
      }

      if (data.length) {
        this.set(search, data);
        //����� ���� ������ ������ - ������ ������ ������ �� ������ ���� return data; - ����� ����� ��������� ������ � cache
        return data.slice(0);
      }
      return false;
    }

    function initCache(){
      var store = sessionStorage.getItem(_localStorageVarName) || '';

      if(!store){
        return;
      }

      cache = JSON.parse(store);
    }
  }

  /**
   * ���� �� ������ � �������� ������
   */
  function WorkFromHistory() {
    var historyList = [];

    this.getHistoryList = function () {
      return historyList;
    }

    this.addFromHistoryList = function (historyString) {
      if (historyList.indexOf(historyString) !== -1) {
        return false;
      }

      historyList.push(historyString);
      rewriteHistory();
    }

    this.clearHistoryList = function () {
      historyList = [];
      cookieObj.deleteCookie('recentsearch');
    }

    this.deleteFromHistoryList = function (historyString) {
      var index = historyList.indexOf(historyString);

      if (index === -1) {
        return false;
      }

      historyList.splice(index, 1);

      rewriteHistory();
    }

    var cookieRecentSearch = cookieObj.getCookie('recentsearch');

    if (cookieRecentSearch && cookieRecentSearch.length) {
      historyList = cookieRecentSearch.split(',');
    }

    function rewriteHistory() {
      var new_cookie = historyList.join();

      if (new_cookie.length > 200) {
        new_cookie = new_cookie.substr(new_cookie.indexOf(',') + 1);
      }

      cookieObj.deleteCookie('recentsearch');
      cookieObj.setCookie('recentsearch', new_cookie);
    }
  }
})();